.gradingflowimage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.5rem 0 0.5rem 0;
  max-width: var(--max-width);

  &__button {
      background-color: initial;
      border: none;
      color: var(--logo-dark-blue);
      cursor: pointer;
      font-size: var(--font-size-small);
      text-decoration: none;
      width: fit-content;

      &:hover {
        text-decoration: underline;
      }
  }

  &__container {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    background-color: var(--background-blue);
  }
  
  &__image {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: 100%;
    object-fit: contain;
  }
}
