/* Sections */
.grid-wrapper {
  width: 100%;
  max-width: var(--max-width);
  margin: 25px auto;
  padding: 0;

  .grid,
  .muuri,
  .dnd {
    .item {
      z-index: 1;
      margin: 0;
      cursor: move;
      transition: 0.3s box-shadow, 0.3s opacity;
      margin: 5px 0;
      &.undragable {
        cursor: pointer;
      }

      .item-content {
        background-color: #fff;
        cursor: default;
        border: 1px solid var(--background-blue);
        border-radius: 5px;

        > .title {
          height: 45px;
          display: flex;
          align-items: center;
          border-radius: 5px;

          .open {
            rotate: 180deg;
          }

          .accordion {
            color: var(--background-blue);
            fill: var(--background-blue);
            background: transparent;
            border: none;
            margin-left: 5px;
            width: 35px;
            height: 35px;
            border-radius: 10px;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            &:hover {
              background: #0033662b;
              cursor: pointer;
            }
          }

          .move-cursor {
            background-color: white;
            border: none;
            border-radius: 5px 0 0 5px;
            cursor: move;
          }

          .text {
              padding: 0 15px;
              width: 100%;
              overflow: hidden;
              margin: 0;
              display: flex;
              align-items: center;
              max-width: calc(100% - 113px);
              max-height: 40px;
              h3 {
                text-wrap: nowrap;
                font-weight: 400;
                font-size: 20px;
                color: var(--background-blue);
                overflow: hidden;
              }
            }

          div.title-actions {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 8px;
            position: relative;

            .more {
              display: flex;
              align-items: center;
              &:hover .more-options,
              &:focus .more-options {
                opacity: 1;
              }
            }

            .more-options {
              position: absolute;
              top: 80%;
              right: 1rem;
              background-color: var(--background-blue);
              transition: all 0.3s ease;
              border-radius: 5px;
              min-width: 140px;
              z-index: 1000;
              border: 1px solid white;
              button {
                display: flex;
                align-items: center;
                width: fit-content;
                margin: 0.5rem;
                padding: 1rem;
                width: 100%;
                p {
                  color: white;
                  padding-left: 10px;
                  margin: 0 !important;
                }
                svg {
                  width: 25px;
                  fill: white;
                }
              }
            }

            button {
              display: inline-block;
              appearance: none;
              border: none;
              background-color: transparent;
              margin-right: 10px;
              height: 25px;
              width: 25px;
              cursor: pointer;
              border-radius: 5px;
              padding: 0;
            }

            svg {
              width: 100%;
              fill: var(--background-blue);
            }
          }
        }

        .item-block {
          padding: 15px;
          max-height: 250px;
          overflow: auto;
          border-top: 1px solid var(--background-blue);
          svg {
            max-height: 240px;
          }
        }
      }
    }
  }

  .grid.vanilla {
    /* Grid. */
    display: grid;
    // 30% for larger screens.
    grid-template-columns: repeat(3, 33%);

    @media screen and (max-width: 1199px) {
      // Half size for smaller screens.
      grid-template-columns: repeat(2, 50%);
    }
    @media screen and (max-width: 950px) {
      // Full width for even smaller screens.
      grid-template-columns: 100%;
    }

    &.singleColumn {
      // Full width for single col.
      grid-template-columns: 100%;
    }

    .item {
      &.dragging {
        cursor: move;
        opacity: 0.5;
      }

      &.over {
        .item-content {
          border: 2px dashed rgba(0, 51, 102, 0.2);
        }
      }
    }
  }

  .muuri {
    .item {
      // Thirds for bigger screens.
      width: 33%;
      @media screen and (max-width: 1199px) {
        // Half size for smaller screens.
        width: 50%;
      }
      @media screen and (max-width: 950px) {
        // Full width for even smaller screens.
        width: 100%;
      }

      &:not(.muuri-item) {
        display: none; // ToDo - Figure out this bug.
        /*
         * Upon removal, a grid item is still painted but without the classes of muuri.
         * All states (items and itemsMemo) shows no item, but it's still in the grid.
         * Maybe a handler not allowing the HTML removal to happen?
         */
      }

      &.muuri-item-positioning {
        z-index: 2;
        opacity: 0.7;
      }

      &.muuri-item-dragging,
      &.muuri-item-releasing {
        z-index: 3;
      }

      &.muuri-item-dragging {
        cursor: move;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      &.muuri-item-hidden {
        z-index: 0;
      }
    }

    /* Single col overrides. */
    &.singleColumn .item {
      width: 100%;
    }
  }

  .dnd {
    .item {
      .comments-block {
        li {
          list-style-type: circle;
        }
        .question-number {
          margin-right: 5px;
        }
      }
    }
  }
}
