.flex {
  display: flex;
  justify-content: center;
}
.centre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--dark-blue);

  h2 {
    align-self: flex-start;
    margin: 0;
  }
}
.report-edit-modal-flex {
  border: none;
  height: 545px;
  .title {
    font-size: 24px;
    margin: 24px 0 30px !important;
  }
  .label {
    font-size: 20px;
    margin: 0px;
    font-weight: 500;
    margin-right: 10px !important;
  }
  .text {
    font-size: 16px;
    margin: 0px !important;
  }

  .section-form {
    display: flex;
    &-title {
      margin-right: 5px;
    }
  }

  .section-menu {
    background-color: var(--dark-blue);
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .underline {
    text-decoration: underline;
  }

  .blue-text {
    color: var(--dark-blue);
    font-size: 1.17em;
  }
  .inline-flex {
    display: flex;
    align-items: center;
    > * {
      margin: 0px;
    }
  }

  .theme-title {
    display: flex;
    &-text {
      width: 100%;
      border-bottom: 2px solid var(--dark-blue);
    }
  }

  .comments-block {
    padding-left: 10px;
    overflow-y: scroll;
    color: black;
    padding: 0 30px;
    &-date-text {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .form-settings {
    display: flex;
    flex-direction: column;
    padding: 25px !important;
    border-right: 1px solid #003366 !important;
    max-height: 545px;

    .date-picker {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &-arrow {
        width: 20px;
        height: 20px;
      }
      &-item {
        align-self: flex-start;

        h3 {
          margin: 0;
        }

        &-input {
          align-self: flex-start;
          display: flex;
          align-items: center;
          width: 100%;
        }
      }
      input {
        border: none;
        box-sizing: border-box;
        position: relative;
        margin: 10px 0;
        font-family: Arboria, sans-serif;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
      input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
      input[type="date"] {
        appearance: none;
        text-align: center;
        border: 2px solid var(--dark-blue);
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        height: 30px;
        color: #003366;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      > * {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 200px;
        margin: 15px 0 !important;
        border-bottom: 1px solid rgba(0, 51, 102, 0.24);
        width: 100%;
        padding: 5px;
      }

      &-item,
      &-questions {
        .question-number {
          margin-right: 5px;
        }
        display: flex;
        align-items: center;
        margin: 0 10px;
        input[type="checkbox"] {
          appearance: none;
          border: 1px solid rgba(0, 51, 102, 0.24);

          min-width: 16px;
          min-height: 16px;
          margin-right: 10px;
          border: 1px solid #003366;

          &:checked {
            background-color: #49e5a6;

            border: 1px solid #003366;
            position: relative;
            &:before {
              color: #003366;
              content: "✓";
              display: inline;
              font-size: 15px;

              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
            }
          }
        }
      }
    }

    &-section-menu {
      background-color: var(--dark-blue);
      color: #fff;
      border-radius: 2px;
      order: 2;
      height: 30px;
      padding: 0 10px;
      width: 100%;
      font-family: Arboria, sans-serif;
      font-size: 16px;
    }

    &-date-picker {
      width: 175px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-title-text {
      &:focus {
        outline: none;
      }
      width: 100%;
      height: 24px;
      border: 2px solid var(--dark-blue);
      border-radius: 3px;
      padding: 0 10px;
      font-family: Arboria, sans-serif;
      font-size: 16px;
    }

    > * {
      margin: 0 0 20px;
    }
  }
  .custom-list {
    margin: 0;
    padding: 0;
    &-item {
      display: flex;
      align-items: center;
    }
  }
  .explanatory-comments li,
  .theme-comments-list {
    list-style-type: circle;
  }

  .question-numbers {
    margin-right: 5px;
    font-weight: bold;
  }
  .circle {
    border: 1px solid var(--dark-blue);
    border-radius: 50%;
    width: 5px;
    margin-right: 5px;
    margin-left: 5px;
    display: inline-block;
    height: 15px;
    width: 15px;
  }
  .border {
    border: 1px solid rgba(0, 51, 102, 0.24);

    padding: 5px;
  }
  .settings-date-range {
    display: flex;
    align-items: center;
  }
  .settings-date {
    margin: 5px 5px;
    border: 2px solid var(--dark-blue);
    padding: 5px;
    border-radius: 5px;
  }
  .question-comments,
  .theme-comments {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 2px solid #003366;
    border-radius: 2px;
  }
}
