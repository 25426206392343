.filter {
  display: flex;
  flex-direction: row;
  align-items: center;

  .inputs {
    max-width: calc(100% - 4rem);
    flex-grow: 1;
    border-bottom: 1px solid #00336630;
    padding: 1.2rem 2rem;

    p,
    input[type="date"] {
      font-family: "Arboria";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }

    p {
      margin: 0;
      margin-top: 8px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      /* Arrow svg. */
      > svg {
        width: 20px;
      }
    }

    input[type="date"] {
      appearance: none;
      position: relative;
      padding: 0 10px;
      font-size: 17px;

      height: 27px;
      width: 40%;

      border-radius: 5px;
      border: 1px solid #003366;
      color: #003366;

      &:hover, &::-webkit-calendar-picker-indicator:hover {
        cursor: pointer;
      }

      &::-webkit-calendar-picker-indicator {
        // display: none;
        // -webkit-appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg width="3000" height="3000" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 2H3C2.44772 2 2 2.44772 2 3V16C2 16.5523 2.44772 17 3 17H16C16.5523 17 17 16.5523 17 16V3C17 2.44772 16.5523 2 16 2Z" stroke="rgb(0 51 102)" stroke-linejoin="round"/><path d="M6 1V4" stroke="rgb(0 51 102)" stroke-width="2" stroke-linecap="round"/><path d="M13 1V4" stroke="rgb(0 51 102)" stroke-width="2" stroke-linecap="round"/></svg>')
      }
    }

    .select-wrapper {
      width: 100%;
      position: relative;
    }

    .filter-type {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        cursor: pointer;
      }
      .invision-button {
        height: fit-content;
        padding: 0.5rem 1rem;
        margin-left: 0.7rem;
        svg {
          fill: white;
        }
      }
    }

    .filter-value {
      margin-top: 0.7rem;
    }
  }
}
