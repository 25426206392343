a.invision-button {
  text-decoration: none;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  .invision-button {
    margin-left: 10px;
    padding: 10px 20px;
    background: #003366;
    border-radius: 4px;
    border-width: 0;

    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* or 211% */

    letter-spacing: 1px;

    /* white */
    color: #ffffff;

    user-select: none;
    cursor: pointer;

    transition: all 200ms ease-in-out;

    /* Secondary */
    &.btn-type-secondary {
      background-color: rgba(0, 0, 0, 0);
      color: #003366;
      border: 1px solid #003366;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--dark-blue);
        color: white;
      }
    }

    /* Remove */
    &.btn-type-remove {
      background-color: transparent;
      color: #003366;
      font-weight: bold;
      margin-left: 0;
      border-radius: 100px;
      padding: 0;
      width: 22px;
      height: 22px;

      svg {
        width: 10px;
        fill: var(--dark-blue);
      }

      &:hover,
      &:active {
        background-color: var(--dark-blue);
        color: white;
        svg {
          fill: white;
        }
      }
    }

    /* Ghost */
    &.btn-type-ghost {
      background-color: rgba(0, 0, 0, 0);
      color: #003366;
      font-weight: bold;
      margin-left: 0;

      &:hover,
      &:active,
      &:focus {
        background-color: #dadbdc;
      }
    }

    /* Red */
    &.btn-type-red {
      background-color: #ff4980;
      color: #fff;

      &:hover,
      &:active,
      &:focus {
        background-color: #c23962;
      }
    }

    /* Green */
    &.btn-type-green {
      background-color: #33e7a6;
      color: #fff;

      &:hover,
      &:active,
      &:focus {
        background-color: #29af7e;
      }
    }

    /* Sizes */
    &.btn-size-small {
      padding: 3px 10px 5px;
      font-size: 12px;
      line-height: 15px;
    }

    &.btn-size-large {
      padding: 8px 14px;
      font-size: 16px;
      line-height: 19px;
    }

    /* Disabled */
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  position: relative;

  .badge {
    position: absolute;
    top: 0;
    transform: translateY(-40%) translateX(40%);
    right: 0;
    background: #fff;
    color: #003366;
    border: 1px solid #003366;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    z-index: 10;
    line-height: 18px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    cursor: default;

    &.secondary {
      background: #003366;
      color: #fff;
      border: 1px solid #003366;
    }
  }
}
