.question {
    padding: 5px 10px;
    background-color: #ffffff;
    transition: background-color 200ms ease-in-out;
    border-top: 1px solid #00336641;

    &.invalid {
      background-color: hsl(210deg 100% 20% / 10%);
    }

    div.base {
      .checkbox {
        margin-right: 1rem;
      }
      h3, label {
        font-family: Arboria;
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: #003366;
      }
      label {
        padding: 1rem 0;
        cursor: pointer;
      }
    }

    div.expandable {
      transition: all 0.3s ease-in-out;
      max-height: 0;
      overflow: hidden;
      padding-right: 0.1rem;

      &.visible {
        max-height: 95px;
      }
    }
  }
  
.multiple-choice-question {
    div.base {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
        width: 250px;
        min-width: 250px;
        margin-left: 1rem;
    }
    }
}

@media (max-width: 700px) {
  .multiple-choice-question {
    div.base {
    flex-wrap: wrap;

    > span {
        margin-left: 0;
        margin-bottom: 1rem;
    }
    }
}
}