div.audit-summary {
  font-family: "Arboria";
  font-style: normal;
  font-weight: 400;
  padding: 0 1rem 10px;
  max-width: var(--max-width);
  margin: auto;

  p.overall {
    padding-left: 10px;
    padding-bottom: 12px;
  }

  div.summary {
    margin-bottom: 2rem;

    div.theme-row {
      padding: 1rem;
      border-bottom: 1px solid #00336641;
      position: relative;
      display: flex;
      align-items: baseline;
    }

    div.overall-row {
      position: relative;
      display: flex;
      align-items: center;
      margin: 1rem 0 2rem;
      font-size: var(--font-size-medium);
    }

    .incomplete {
      background-color: hsl(210deg 100% 20% / 10%);
    }
    div.overall-row,
    div.theme-row {
      transition: 0.3s all ease;
      h4,
      h6 {
        margin: 0;
        font-size: var(--font-size-medium);
        color: var(--dark-blue);
      }

      h4 {
        margin-bottom: 15px;
        line-height: 20px;
      }

      span.theme-name {
        margin-left: 6px;
        color: var(--dark-blue);
      }

      .fixed-grade {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
      }
      .grade {
        width: fit-content;
        display: flex;
        align-items: flex-end;
        p {
          margin: 0 0 0 1rem !important;
          padding-right: 1rem;
        }
        .chip {
          align-self: center;
          border-radius: 5px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .sections-grading {
    border: 1px solid rgba(0, 51, 102, 0.2549019608);
    border-radius: 4px;
  }

  div.actions {
    margin-bottom: 2rem;

    h4 {
      margin: 0;
      font-size: var(--font-size-medium);
      margin-bottom: 1rem;
    }

    div.action-row {
      margin-bottom: 8px;
      display: flex;
      align-content: center;
      justify-content: flex-start;

      img {
        width: 15px;
        height: 15px;
        border-radius: 40px;
        border: 1px solid #013365;
        padding: 4px;
        cursor: pointer;
      }

      img.tick {
        filter: brightness(2) hue-rotate(270deg);
      }
      img.cross {
        display: none;
      }

      &:hover {
        img.tick {
          display: none;
        }
        img.cross {
          display: initial;
          filter: brightness(2) hue-rotate(140deg);
          transform: rotate(45deg);
        }
      }

      p {
        margin: 0;
        margin-left: 10px;
        padding-top: 1px;
        word-break: break-all;
      }

      input {
        appearance: none;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        width: 100%;
        margin-left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        font-family: "Arboria";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
