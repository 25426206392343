div.form-toggle {
  div.buttons {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;

    button {
      width: 50%;
      padding: 7px 10px;
      appearance: none;
      border: none;
      // border-radius: 8px;
      border-width: 0;

      font-family: Arboria;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 1px;

      user-select: none;
      cursor: pointer;

      background-color: #fff;
      color: #003366;

      &.primary {
        background: #003366;

        /* white */
        color: #ffffff;
      }
    }
  }
}
