div.review-summary-modal {
  display: flex;
  flex-direction: row;

  div.themes {
    width: 30%;

    /* Sticks to the top of modal. */
    align-self: flex-start;
    position: sticky;
    top: 0;

    h3 {
      cursor: pointer;
      &:nth-child(1) {
        cursor: default;
      }

      padding: 8px;
      line-height: 14px;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 8px;

      &.active {
        background-color: #ebebeb;
        border-radius: 8px;
      }

      span:not(.chip) {
        flex-grow: 1;
        line-height: 1.2rem;
      }

      span.chip {
        text-align: center;
        border-radius: 9999px;
        padding: 5px 10px;
        font-family: Arboria;
        margin: 11px 7.5px;
        text-transform: uppercase;
        color: #485f76;
        font-size: 0.8rem;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        background-color: #cacaca;

        &.chip-gold {
          background-color: #ffd260;
        }

        &.chip-silver {
          background-color: #c6c6c6;
        }

        &.chip-bronze {
          background-color: #e7b180;
        }
      }

      span.float-right {
        float: right;
        margin-right: 10px;
        border-radius: 9999px;
        padding: 5px 10px;
        font-family: Arboria;
        text-transform: uppercase;
        color: #485f76;
        font-size: 0.8rem;
        flex-grow: 0;
        background-color: #cacaca;
      }
    }
  }
  .criteria-group {
    padding-bottom: 1rem;
  }
  div.questions {
    width: 70%;
    padding: 10px 10px 0;


    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        background-color: #ebebeb;
        border-radius: 8px;
        padding: 10px;

        margin-bottom: 10px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        h5 {
          margin-top: 0;
        }

        > div {
          background-color: #ffffff;
          border-radius: 8px;
          padding: 10px;
        }
      }
    }
  }
}
