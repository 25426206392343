.accordion {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;

  .accordion-header {
    background-color: #f5f6f7;
    padding: 5px 15px;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;

    h3 {
      margin: 5px 0;
      color: black;
    }

    &:hover {
      background-color: #d9e8f8;
    }

    // Caret for accordion.
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 15px;
      top: calc(50% - 8px);
      width: 0px;
      height: 0px;

      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid #2f2f2f;

      transition: transform 0.3s, top 0.3s;
    }
  }

  &.is-open .accordion-header:after {
    transform: rotate(-90deg);
    top: calc(50% - 6px);
  }

  .accordion-content {
    position: relative;
    padding: 5px 10px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-out;
    .question {
      border: none;
      border-bottom: 1px solid #00336641;
      &:last-child {
        border: none;
      }
    }
    &__overflow {
      overflow: visible;
    }
  }

  &.is-open .accordion-content {
    /* Max height should be more than the highest expected amount. */
    max-height: 2000px;
    opacity: 1;
    transition: all 0.3s ease-in;
  }
}
