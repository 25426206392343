div.assign-audits {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  > * {
    flex: none;
  }

  div.auditor {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    span {
      flex: 60px;
    }

    input {
      flex: auto 1;
      margin-bottom: 0;

      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  div.search-wrapper {
    background: #f7f7f7;
    border-radius: 8px 8px 0px 0px;
    padding: 12px 8px;

    input {
      height: 1.5rem;
      line-height: 1.5rem;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background: #ffffff;
      border-radius: 8px;
      font-size: 1rem;
      width: calc(50% - 100px);
      padding: 0 15px;
    }
  }

  div.table-wrapper {
    flex: auto;
  }

  span {
    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #003366;
    padding-right: 16px;
  }

  .audit-field {
    height: 1.5rem;
    line-height: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #ebebeb;
    border-radius: 8px;
    font-size: 1rem;
    width: calc(50% - 100px);
    padding: 0 15px;
  }

  div.assignees {
    text-align: left;

    svg,
    img.assignees {
      width: 20px;
      display: inline-block;
      vertical-align: middle;

      &.faded {
        opacity: 0.5;
      }
    }

    span {
      font-size: 0.8rem;
      line-height: 20px;
      padding: 0 5px;
      display: inline-block;
    }
  }
}
