div.rating-wrapper {
  margin: auto;
  max-width: var(--max-width);
  padding: 1.5rem 1.5rem 0;
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;

  div.rating {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 8px;

    * {
      font-size: var(--font-size-small);
    }

    span {
      font-family: Arboria;
      font-style: normal;
      font-weight: 400;
      color: var(--background-blue);
      margin-right: 8px;
    }

    a,
    button {
      text-align: center;
      border-radius: 4px;
      margin: 0 5px;
      padding: 0.3rem 0;
      font-family: Arboria;
      color: #000000;
      font-weight: 500;

      /* Normalise button styles. */
      appearance: none;
      border: none;
    }

    a:not(.title_Key),
    button:not(.title_Key) {
      flex-grow: 1;
    }

    a:not(.active),
    button:not(.active) {
      opacity: 0.2;
    }
  }

  div.progress {
    border-radius: 4px;
    // padding: 3px;
    background: #ffffff;
    border: 1px solid var(--background-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div.progress-1 {
      background-color: var(--background-blue);
      color: #ffffff;
      border-radius: 2px 0 0 2px;
      text-align: right;
      height: 21px;
    }

    span {
      padding: 0 10px;
    }
  }
}
