/**
 * CSS file used for all report block components.
 */
div.report-block {
  display: flex;
  justify-content: center;

  // height: 300px; // Target height

  color: black;
  background: #ffffff;

  &.broken-widget {
    text-align: center;

    p {
      font-weight: 500;
      font-size: 35px;
      color: black;
    }
  }

  &.add-widget {
    cursor: pointer;
    transition: background-color 0.5s ease;
    border: none;

    // height: 345px; // Taller as we have no title.

    // Bordered add block.
    .bordered {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 20px;
      padding: 8px;
      margin: 5px 0;
      border-radius: 5px;
      color: white;
      background-color: var(--dark-blue);
      transition: all 0.3s ease;

      // Hover affect.
      &:hover,
      &:focus,
      &:active {
        background-color: white;
        color: var(--dark-blue);
        cursor: pointer;
      }
    }

    // Blocks selection.
    .blocks {
      width: 100%;

      .block {
        display: flex;
        align-items: center;
        justify-content: left;
        fill: white;
        background-color: var(--dark-blue);
        border: 1px solid #003366;
        border-radius: 10px;
        margin: 2px;
        padding: 10px 2rem;
        color: white;

        &:hover,
        &:focus,
        &:active {
          background-color: white;
          fill: var(--dark-blue);
          color: var(--dark-blue);
        }

        svg {
          width: 30px;
        }

        p {
          margin: 0 0 0 1rem !important;
          text-align: center;
        }
      }
    }
  }

  &.custom-text {
    display: flex;
    flex-direction: column;

    border: 1px solid #003366;
    border-radius: 10px;

    height: 240px; // Shorter as we have a border.

    .title-bar {
      height: 45px;
      background: #003366;
      border-radius: 10px 10px 0px 0px;
      color: #ffffff;
      padding: 0 10px;

      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        display: inline-block;
        background: none;
        border: none;
        padding: 0 2px;
        margin: 0;
        margin-right: 5px;

        height: 30px;
        width: 35px;
        border-radius: 8px;

        cursor: pointer;
        color: white;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }

        &.is-active {
          background-color: white;
          color: var(--dark-blue);

          > svg {
            filter: invert(1);
          }
        }

        > svg {
          max-width: 20px;
        }
      }
    }

    .textarea {
      display: flex;
      flex-grow: 1;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      /* Placeholder */
      .ProseMirror p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }

      > div {
        box-sizing: content-box;
        width: 100%;
        padding: 0 15px;
        outline: none;

        /* Text styling (to correct site-wide styles). */
        h1,
        h2,
        h3,
        h4 {
          display: block;
          margin: 8px 0;
          padding: 0;
        }

        h1 {
          font-size: 2.4rem;
        }
        h2 {
          font-size: 1.7rem;
        }
        h3 {
          font-size: 1.5rem;
        }
        h4 {
          font-size: 1.2rem;
        }

        ul,
        ol {
          margin-left: 0;
        }

        ul li {
          list-style-type: disc;
        }
        ol li {
          list-style-type: decimal;
        }
      }
    }
  }

  &.chart {
    display: block;
    height: auto;
    .legend {
      ul {
        list-style-type: none;
        font-size: 0.8rem;

        li {
          margin-bottom: 5px;
          display: flex;
          align-items: center;

          .square {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.comments {
    flex-direction: column;
    justify-content: flex-start;

    > h3,
    > h4 {
      margin: 10px 0 5px;
    }
  }
}
.comments-block {
  color: black;
}