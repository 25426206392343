p.moderated-audits-blurb,
div.moderated-audits {
  .chip {
    text-align: center;
    border-radius: 9999px;
    padding: 5px 10px;
    font-family: Arboria;
    margin: 11px 7.5px;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
    border: none;
    background-color: #999999;
    color: white;

    &.chip_Gold {
      background-color: #ffd260;
      color: #485f76;
    }

    &.chip_Silver {
      background-color: #c6c6c6;
      color: #485f76;
    }

    &.chip_Bronze {
      background-color: #e7b180;
      color: #485f76;
    }

    &.chip_nominated {
      background-color: #6b95ff;
      color: #fff;
    }
  }
}

div.moderated-audits {
  width: 100%;

  tr {
    border: 1px solid rgb(178 178 178);
  }

  table {
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: collapse;
    background-color: #fff;
    width: 100%;

    margin: 0 auto;

    tr {
      .head-cell {
        vertical-align: bottom;
      }

      &:nth-child(2n) {
        background-color: #eee;

        > td.nominated {
          background-color: rgb(212 220 222);
        }
      }

      td {
        padding: 15px;
        width: 200px;

        &.nominated {
          background-color: rgb(237 245 247);
        }

        &:not(.tr-header) {
          text-align: center;
        }

        &.tr-header {
          width: 150px;
          font-weight: bold;
          position: sticky;
          z-index: 1;
          left: 0;
          border: 1px solid rgb(178 178 178);
          background-color: #fff;

          &::before {
            content: "";
            position: absolute;
            height: 100%;
            left: -1px;
            top: 0;
            border-right: 1px solid rgb(178 178 178);
          }

          &::after {
            content: "";
            position: absolute;
            height: 100%;
            right: -1px;
            top: 0;
            border-right: 1px solid rgb(178 178 178);
          }

          small {
            display: block;
          }
        }

        &.compare {
          width: 40px;
          text-align: center;

          .indicator {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin: 0 auto;

            &.green {
              background-color: #33e7a6;
            }

            &.red {
              background-color: #ff4980;
            }
          }
        }

        label {
          cursor: pointer;
        }
      }
    }
  }
}
