div.explanatory-comments {
  h3 {
    margin-block-start: 0;
  }

  div.form > span {
    color: #003366;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-auto-rows: 1fr;

  .column {
    .card {
      box-sizing: border-box;
      height: 100%;
    }
  }
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  padding: 16px;
  text-align: left;
  background-color: #f1f1f1;
  border-radius: 12px;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    h3 {
      flex-grow: 1;
    }

    img {
      border-radius: 50%;
      border: 1px solid #003366;
      height: 13px;
      width: 13px;
      padding: 4px;
    }
  }

  &.active > div img {
    background-color: #ccffce;
  }

  &:not(.active) {
    opacity: 0.6;
  }

  // svg {
  //   width: 16px;
  //   height: 16px;
  // }
}
