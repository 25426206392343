.feedback-header {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;

  align-items: center;
  background: linear-gradient(224.93deg, #00b3bb, #34319e 80.46%);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50px;
  justify-content: flex-start;
  padding: 0 22px;

  span.logo {
    flex-basis: 60px;
  }

  h1 {
    color: white;
    flex-basis: calc(100% - 60px);
    font-size: 1.2rem;
  }
}
