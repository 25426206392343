#topbar {
    padding: 0rem 1rem;
    background: var(--logo-gradient);
    color: var(--brand-white);
    > div {
        height: 55px;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;
        h1 {
            font-size: var(--font-size-big);
            font-family: Arboria;
            margin: 0;
        }
        > .usermenu {
            cursor: pointer;
            border: none;
            background-color: var(--background-blue);
            height: 35px;
            width: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-self: flex-end;
            transition: all 0.3s ease;
            svg {
              fill: var(--brand-white);
              padding-left: 4px;
            }
            &:hover {
                background-color: var(--brand-white);
                svg {
                    fill: var(--background-blue);
                }
            }
        }
        .logo {
            justify-self: flex-start;
            width: 100px;
            height: auto;
            svg {
              display: block;
            }
        }
    }
}
@media (max-width: 700px) {
    #topbar {
        > div {
            h1 {
                font-size: var(--font-size-small);
            }
        }
        #Name {
            display: none;
        }
    }
}