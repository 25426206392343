.comment-box {
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 100%;
  textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    border: none;
    display: block;
    font-family: Arboria;
    font-size: var(--font-size-small);
    box-sizing: border-box;
    resize: vertical;

    &:focus {
      outline: 1px solid var(--background-blue);
    }
    &::placeholder {
      font-family: "Arboria";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: var(--background-blue);
    }
  }
  .character-count {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      margin: 0;
      color: #cccccc;
    }
  }
}
