html,
body,
div#root,
div.left-nav-wrap {
  height: 100%;
  background-color: #ebebeb;
}

div#root {
  display: flex;
  flex-direction: column;
}

@import url("https://p.typekit.net/p.css?s=1&k=vno7lmq&ht=tk&f=34863.34865.34866&a=2157170&app=typekit&e=css");

@font-face {
  font-family: "arboria";
  src: url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "arboria";
  src: url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "arboria";
  src: url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

.tk-arboria {
  font-family: "arboria", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.app-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #ebebeb;
  overflow: hidden;
}

div.blue-wrapper {
  height: 100%;
  padding: 50px;
  background: linear-gradient(60deg, rgb(52, 49, 158), rgb(0, 179, 187));

  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 390px) {
  div.blue-wrapper {
    padding: 0 25px;
  }
}

/* Default font face. */
label {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

div.table-actions button {
  margin-left: 0;
}

/* Forces modal to be above UI. */
.ReactModalPortal .ReactModal__Overlay {
  z-index: 100;
}

/* Fixes modal being too wide. */
.ReactModalPortal .ReactModal__Content {
  max-width: 1200px;
  margin: auto;
}

.ReactModal__Overlay:has(.experiential-page-filters) {
  z-index: 999;
}

.text-center {
  text-align: center;
}

/* Common content wrapper. */
div.container__main div.content {
  border-radius: 8px;
  padding: 10px 1rem;
}

div.grower {
  flex-grow: 1;
}

/* Change the default colours and text for date ranges. */
.react-daterange-picker span,
.react-daterange-picker input {
  color: #003366 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

/* Overrides the block ui component stlying. */
.block-ui-message-container {
  display: flex;
  justify-content: center;
}

/* Error boundary styling. */
div.error-boundary {
  padding: 15px;
  text-align: left;

  span.button-wrapper {
    flex-direction: row;
    justify-content: flex-start;

    a.invision-button {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  pre {
    font-size: 0.8rem;
    background-color: #ececec;
    border: 1px solid #369;
    padding: 10px;
    border-radius: 8px;
    overflow-x: scroll;
  }
}
