.question {
    padding: 5px 10px;
    background-color: #ffffff;
    transition: background-color 200ms ease-in-out;
    border-top: 1px solid #00336641;

    div.base {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        > span {
            width: 200px;
            min-width: 200px;
        }
    }
    
    &.invalid {
      background-color: hsla(210deg, 100%, 20%, 0.1);
    }

    div.base {
      h3 {
        font-family: Arboria;
        font-size: var(--font-size-small);
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: #003366;
      }
    }
}

.text-input {
    background: #ebebeb;
    width: 100%;
    border-radius: 8px;
    border: hsl(0, 0%, 80%);
    padding: 10px;
    display: block;
    box-sizing: border-box; /* Opera/IE 8+ */
    font-family: Arboria;
    font-size: 16px;
    
    &::placeholder {
      color: #a8b3c3;
      font-family: Arboria;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;
    }
  }