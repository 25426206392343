div.max-width {
  max-width: var(--max-width);
}
div.page-title {
  padding: 2rem 1rem 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;

  > * {
    color: var(--background-blue);
  }

  span {
    font-family: Arboria;
    font-size: var(--font-size-big);
    font-style: normal;
  }

  input {
    max-width: 173px;
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 18px;
    color: #003366;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 95% center;
    border-radius: 50px;
    border: 1px solid #003366;
    padding-right: 1px;

    &::placeholder {
      color: color(#003366 a(0.8));
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  }
}
