.modal_container {
    padding: 50px 50px 70px;
    display: grid;
    grid-template-columns: 1fr auto;
    .content {
        padding-right: 25px;
    }

    h3 {
        font-size: 25px;
    }

    .image {
        max-width: 300px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

@media (max-width: 700px) {
    .modal_container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        .content {
            padding-bottom: 25px;
        }
        .image {
            max-width: 200px;
        }
    }
}