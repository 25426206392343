.ReactModal__Content.confirm-modal {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  // Make confirm smaller.
  width: 400px;
  margin: 0 auto;
  top: calc(50% - 100px);

  .modal-content {
    flex: none;
  }

  .modal-footer {
    margin-top: 40px;
  }
}
