div.query-builder-component {
  .conditions {
    .bar {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 12px;

      .selector {
        flex-grow: 2;
      }

      h2 {
        color: #485f76;
        font-family: Arboria;
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 10px;
        /* or 111% */
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .condition-fields {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      > * {
        max-height: 40px;
      }

      .filter {
        margin-right: 8px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        > * {
          margin: 0 4px;
        }
      }
    }
  }

  div.content {
    background: #ffffff;
    padding: 24px;
    margin-top: 64px;
  }

  div.filter {
    background: #ebebeb;
    border-radius: 8px;
    padding: 8px 10px;
    display: flex;
    max-width: 400px;

    &.compare-dropdown {
      max-width: 500px;
    }
    &.single-dropdown {
      min-width: 250px;
      > div {
        flex-grow: 1;
      }
    }

    h4 {
      font-family: Arboria;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 10px;
      /* identical to box height, or 111% */

      letter-spacing: 1px;

      /* Detail text grey */

      color: #485f76;
    }
  }
}

div.recharts-wrapper {
  background: #ffffff;
  border-radius: 8px;
}
