.prompt {
  position: relative;
  background: white;
  box-shadow: 0px 10px 20px rgba(100, 100, 100, 0.5);
  border-radius: 16px;
  // height: 570px;
  max-width: 460px;
  width: 100%;
  padding: 1rem 2rem;

  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;

  img {
    display: block;
    max-width: 180px;
    width: 100%;
    margin: 0 auto 20px;
  }
}

@media (max-width: 390px) {
  div.blue-wrapper {
    padding: 25px;
  }
}

// .prompt {
//   font-family: Arboria;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 19px;
//   letter-spacing: 1px;

//   border: 1px solid #c0c0c0;
//   border-radius: 8px;
//   background-color: #fff;

//   max-width: 460px;
//   width: 100%;

//   margin: 20px auto;
//   padding: 20px;

//   img {
//     display: block;
//     max-width: 180px;
//     width: 100%;
//     margin: 0 auto 20px;
//   }

//   .block-ui-message {
//     display: inline-block;
//   }
// }
