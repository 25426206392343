p {
  font-family: Arboria !important;
}

.side-wrapper {
  padding: 0;
  overflow: hidden;
  background: var(--brand-white);
  display: grid;
  grid-template-columns: fit-content(60px) auto;
  grid-template-rows: auto fit-content(100px);
  height: 100%;
  position: relative;

  .action-bar {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .jw-drawer {
    min-height: 300px;
    width: 330px;
    will-change: width;

    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--logo-dark-blue);
    position: relative;
    height: 100%;
    transition: width 300ms ease-in-out;

    ul {
      padding: 0;
      margin: 0;
      width: fit-content;
      position: absolute;
      top: 0;
      right: 0;
    }

    &::-webkit-scrollbar {
      background-color: var(--background-blue);
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(0 179 187 / 50%);
      border-radius: var(--border-radius);
    }

    // li:first-of-type > * {
    //   border-top-left-radius: 8px;
    //   border-top-right-radius: 8px;
    // }
    // li:last-of-type > * {
    //   border-bottom-left-radius: 8px;
    //   border-bottom-right-radius: 8px;
    // }

    .menuItemSide {
      border: none;
      padding: var(--font-size-small) 15px;
      margin: 0;
      text-decoration: none;
      display: inline-block;
      display: grid;
      align-items: center;
      grid-template-columns: auto 35px;
      width: 300px;
      font-family: Arboria;
      font-size: var(--font-size-small);
      color: var(--brand-white);
      z-index: 1;
      position: relative;
      transition: all 150ms ease-in-out;

      svg {
        transition: all 300ms ease;
        padding-left: 5px;
        justify-self: center;
        max-height: 30px;
        fill: white;
      }

      &.disabled {
        opacity: 0.5;
        // border: 1px solid #ffffffcc;
      }

      &:hover:not(.disabled) {
        color: var(--background-blue);
        > svg {
          fill: var(--background-blue);
        }
        // background-color: var(--brand-white);
      }

      &:not(.disabled) {
        cursor: pointer;
      }
    }

    li.active {
      background: var(--brand-white);
      color: var(--background-blue);
      > .menuItemSide {
        color: var(--background-blue);
        svg {
          fill: var(--background-blue);
        }
      }
    }

    li {
      margin: 0px;
      padding: 0;
      width: 100%;
      list-style: none;
      position: relative;
      &:hover:not(.disabled) {
        color: var(--background-blue);
        // background-color: var(--brand-white);
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        background: var(--brand-white);
        transition: all 300ms ease;
        opacity: 0;
      }

      &:hover:not(.disabled)::before {
        left: 0%;
        opacity: 1;
      }

      transition: all 150ms ease-in-out;
    }
  }

  .container__main {
    /* Make it scrollable */
    overflow: auto;
    height: 100%;
    position: relative;
  }
  > .content {
    /* Take the remaining width */
    grid-column-end: 3;

    /* Make it scrollable */
    overflow: auto;
    position: relative;
  }

  .collapsed-nav {
    width: 60px;

    button.side-nav {
      span {
        &:first-child {
          top: 35%;
          transform: translateY(-50%) rotateZ(0deg);
        }
        &:last-child {
          top: 65%;
          transform: translateY(-50%) rotateZ(0deg);
        }
      }
    }
  }

  button.side-nav {
    cursor: pointer;
    background-color: var(--background-blue);
    color: var(--brand-white);
    width: 330px;
    border: none;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;

    > div {
      align-self: center;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        margin: 0 !important;
      }
      svg {
        width: 100px;
        margin-left: 10px;
      }
    }

    span {
      will-change: transform, top;
      display: block;
      position: absolute;
      right: 17px;
      top: 50%;
      transition: all 300ms ease;
      background-color: var(--brand-white);
      width: 25px;
      height: 4px;
      border-radius: var(--border-radius);
      &:first-child {
        transform: translateY(-50%) rotateZ(45deg);
      }
      &:last-child {
        transform: translateY(-50%) rotateZ(-45deg);
      }
    }
  }
}
.no-nav {
  grid-column-start: 1;
}
