div.audit-wrapper {
  padding: 2rem 1.5rem 0.5rem;
  max-width: var(--max-width);
  margin: auto;
}

.multiple {
  position: relative;
  margin: 12px 25px 20px;
  border: 1px solid var(--background-blue);
  padding: 10px;
  border-radius: 4px;
  & ~ .multiple {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 28px;
  }
  & .question {
    border-top: none;
    & ~ .question {
      border-top: 1px solid #00336641;
    }
  }
  .number-ball {
    position: absolute;
    height: 24px;
    width: 24px;
    left: -12px;
    top: -12px;
    background: white;
    border: 1px solid #003366;
    color: #003366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      margin-bottom: 3px !important;
      margin-right: 2px;
    }
  }
}
div.audit-title {
  margin-bottom: 10px;
}
div.audit-component {
  padding: 1rem 0;
  .qa-title {
    display: flex;
    align-items: baseline;
    position: relative;
  }
  h2 {
    margin-right: 1rem;
    font-family: "Arboria";
    font-size: var(--font-size-big);
    font-style: normal;
    text-align: left;
    color: var(--background-blue);
  }
}
.section-comment {
  margin-bottom: 0.3rem;
}
.comment-button {
  margin-top: 0.5rem;
  font-family: "Arboria";
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-size: var(--font-size-small);
  color: var(--logo-dark-blue);
  cursor: pointer;
}
