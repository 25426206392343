.radio-wrapper {
  &:not(.inline) {
    & > label {
      display: block;
      height: 30px;
    }
  }
  &.inline > label {
    margin-right: 10px;
  }

  span.radio-label {
    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-right: 10px;
  }

  label {
    line-height: 38px;
  }

  input {
    border-radius: 8px;
    border: 1px solid #ccc;

    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    padding: 0 10px;
    margin-bottom: 15px;

    /* or 81% */
    letter-spacing: 0.2px;

    &[disabled] ~ label {
      /* text-soft grey */
      color: #a8b3c3;
    }
  }
}
