input.forminput {
  border-radius: 8px;
  border: 1px solid #ccc;

  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  height: 38px;
  line-height: 38px;
  padding: 0 10px;
  margin-bottom: 15px;

  /* or 81% */
  letter-spacing: 0.2px;

  /* Inline displays block to move under label. */
  &.inline {
    margin-left: 10px;
  }
  &:not(.inline) {
    display: block;
    margin-top: 5px;
  }

  // Full is 100% width.
  &.full {
    box-sizing: border-box;
    width: 100%;
  }

  &[disabled] {
    border: 1px solid #ebebeb;
    background-color: #ebebeb;
    /* text-soft grey */
    color: #a8b3c3;
  }
}
