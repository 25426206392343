div.ma-feedback {
  padding: 25px 40px 30px;
  border-radius: 8px;

  h2 {
    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    color: #003366;

    margin-bottom: 24px;
  }

  div.link {
    margin-bottom: 20px;

    > span {
      font-family: Arboria;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 10px;

      letter-spacing: 1px;
      text-transform: uppercase;

      color: #485f76;
    }
    > div {
      padding: 5px;
      background: #dadbdc;
      border-radius: 8px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      > span {
        font-family: Arboria;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: #003366;
      }

      button {
        width: 130px;
        float: right;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 5px;
        background: #003366;
        border-radius: 8px;
        border: none;
        outline: none;

        font-family: Arboria;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #fff;
        cursor: pointer;

        &.copied {
          background: #00b3bb;
        }

        img,
        svg {
          display: inline-block;
          line-height: 1rem;
          padding-left: 15px;
        }
      }
    }
  }
}
