form.feedback {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  align-items: center;

  div.question {
    margin-bottom: 40px;
    width: 100%;
    max-width: 600px;

    h3 {
      margin: 10px auto;
      color: #003366;
      margin-bottom: 30px;

      /* Produces a reverse indent for the number. */
      margin-left: 30px;
      text-indent: -30px;

      span {
        color: #fff;
        border-radius: 8px;
        padding: 0px 5px 3px;
        margin-left: 5px;
        font-size: 0.8rem;

        &.required {
          background-color: #ff4980;
        }

        &.optional {
          background-color: #33e7a6;
          color: #000;
        }
      }
    }

    div.answers {
      button.answer {
        appearance: none;
        border: none;
        cursor: pointer;

        display: block;
        line-height: 21px;
        width: 100%;
        max-width: 400px;
        margin: 10px auto;
        padding: 10px;
        border-radius: 8px;
        color: #003366;

        transition: 0.3s background-color;

        background-color: #ebebeb;

        /* Select glyph. */
        .select-icon {
          float: right;
          &.left {
            float: left;
          }

          width: 15px;
          height: 15px;
          border-radius: 50%;

          border: 3px solid #ffff;
          background-color: #fff;
        }

        /* Emoji symbol. */
        .emoji-icon {
          float: left;
          font-size: 1.5rem;
        }

        &:hover {
          background-color: #cccccc;
        }

        &.selected {
          background-color: #02b3ba;
          .select-icon {
            background-color: #ffffff00;
          }

          &:hover {
            background-color: #009ba0;
          }
        }
      }

      // Additional comment button.
      .additional-comment-button {
        width: 90%;
        max-width: 400px;
        padding: 10px;
        display: block;
        margin: 0 auto;

        appearance: none;
        background: none;

        border: none;
        text-align: center;
        cursor: pointer;
        color: #003366;

        span {
          font-size: 2rem;
          vertical-align: middle;
        }
      }

      // Alternate textarea.
      textarea {
        font-family: Arboria;
        font-style: normal;
        font-weight: normal;

        width: 90%;
        padding: 10px;
        border-radius: 8px;
        border: 2px solid #cccccc;
        display: block;
        margin: 0 auto;

        &:active,
        &:focus {
          border: 2px solid #02b3ba;
        }
      }
    }
  }

  div.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
}
