.button-bar {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-end;
  align-content: center;




  > label > input {
    margin: 0;
    margin-left: 10px;
    height: 36px;
    border-radius: 4px;
  }

  > div button {
    height: 40px;
  }

  > div.select {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
  }

  > label.atop {


    align-items: center;
    margin-left: 10px;


    > span {
      margin-right: 10px;
      padding-left: 4px;


      &:empty {
        margin: 0;
      }
    }

    > input {
      margin-left: 0;
    }

    > .react-daterange-picker .react-daterange-picker__wrapper {
      height: 38px;
      border-radius: 4px;
    }
  }
}
