form.feedback {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;

  div.question {
    margin-bottom: 40px;

    h3 {
      margin: 10px auto;
      color: #003366;

      /* Produces a reverse indent for the number. */
      margin-left: 20px;
      text-indent: -20px;
    }

    div.answers {
      button.answer {
        appearance: none;
        border: none;
        cursor: pointer;

        display: block;
        line-height: 21px;
        width: 100%;
        max-width: 400px;
        margin: 10px auto;
        padding: 10px;
        border-radius: 8px;
        color: #003366;

        transition: 0.3s background-color;

        background-color: #ebebeb;

        /* Select glyph. */
        .select-icon {
          float: right;
          &.left {
            float: left;
          }

          width: 15px;
          height: 15px;
          border-radius: 50%;

          border: 3px solid #ffff;
          background-color: #fff;
        }

        /* Emoji symbol. */
        .emoji-icon {
          float: left;
          font-size: 1.5rem;
        }

        &:hover {
          background-color: #cccccc;
        }

        &.selected {
          background-color: #02b3ba;
          .select-icon {
            background-color: #ffffff00;
          }

          &:hover {
            background-color: #009ba0;
          }
        }
      }

      // Alternate textarea.
      textarea {
        font-family: Arboria;
        font-style: normal;
        font-weight: normal;

        width: 90%;
        padding: 10px;
        border-radius: 8px;
        border: 2px solid #cccccc;
        display: block;
        margin: 0 auto;

        &:active,
        &:focus {
          border: 2px solid #02b3ba;
        }
      }
    }
  }
}
