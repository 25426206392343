:root {
  --background-blue: #003366;
  --logo-dark-blue: #34319e;
  --logo-light-blue: #00b3bb;
  --dark-blue: #003366;
  --logo-gradient: linear-gradient(
    226.89deg,
    #00b3bb 1.37%,
    #34319e 74.18%
  ); /* linear-gradient(60deg, var(--logo-dark-blue), var(--logo-light-blue))*/
  --brand-white: white;
  --border-width: 3px;
  --border-radius: 20px;
  --font-size-big: 1.5rem;
  --font-size-small: 1rem;
  --font-size-medium: 1.3rem;
  --max-width: 1400px;
}

#topNav {
  height: 35px;
  background-color: var(--brand-white);
  border-bottom: var(--border-width) solid var(--background-blue);

  ul {
    height: 101%;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin: 0;
    list-style-type: none;

    > * {
      color: var(--background-blue);
      font-family: Arboria;
      font-size: var(--font-size-medium);
      text-decoration: none;
      transition: all 200ms ease-in-out;
    }

    > .route {
      position: relative;
      margin: 0;
      height: 100%;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        content: " ";
        color: transparent;
        background: var(--background-blue);
        height: 0%;
        transition: all 300ms ease;
      }
      a {
        text-decoration: none;
        font-size: var(--font-size-small);
        color: var(--background-blue);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 101%;
      }
    }

    > :not(.logo) {
      text-align: center;
    }

    > .route:not(.active) {
      &:hover {
        a {
          color: var(--brand-white);
        }
        &::before {
          height: 100%;
        }
      }
    }
    .active {
      cursor: default;
      a {
        color: var(--brand-white);
      }
      &::before {
        height: 100%;
      }
    }
  }

  span.logo {
    flex-basis: 200px;
  }
}
