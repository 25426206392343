footer.action-bar {
  background: #003366;
  height: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 17px;

  > * {
    margin: 0 5px;
  }

  > :first-child {
    margin-left: 0;
  }

  > :last-child {
    margin-right: 0;
  }

  &.audit .active {
    // background: linear-gradient(224.93deg, #00b3bb 0%, #34319e 80.46%);
    border: 2px solid white;
    opacity: 1;
  }

  > :not(:last-child) {
    color: white;
    border-radius: 8px;
    opacity: 0.5;
    padding: 2px 10px 8px 8px;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: Arboria;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
    }

    .step-label {
      font-family: Arboria;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      color: #ffffffcc;
    }

    svg {
      float: right;
      width: 20px;
      height: 20px;
    }
  }

  > :last-child {
    margin-left: auto;
  }

  button {
    border-radius: 8px;
    border: none;
    padding: 15px 10px;
    cursor: pointer;

    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    transition: all 200ms ease-in-out;

    /* Background Colour */

    color: #003366;

    background-color: #ffffff;

    &:disabled,
    &.disabled {
      background-color: #ffffff4d;
    }

    &:hover,
    &:active {
      background-color: #ffffffc4;
    }

    span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}
