.report-modal-form {
  font-family: "Arboria";
  font-style: normal;
  color: #003366;

  .form-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
  }

  .form-item {
    margin: 20px 0;

    label {
      font-weight: 500;
      font-size: 20px;

      /* Aligns text with input. */
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    button {
      font-family: Arboria !important;
      margin: 0;
    }

    input[type="text"] {
      border-radius: 2px;
      border: 1px solid #003366;

      height: 24px;
      margin-left: 10px;

      flex-grow: 1;
    }

    &.form-checkbox {
      label {
        /* Pushes box to baseline. */
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        input[type="checkbox"] {
          appearance: none;
          position: relative;
          cursor: pointer;
          border: 1px solid #003366;
          width: 18px;
          height: 18px;
          margin-right: 12px;

          &:checked {
            background-color: #49e5a6;
          }

          &:checked::before {
            content: "";
            border-left: 2px solid #003366;
            border-bottom: 2px solid #003366;
            position: absolute;
            left: 2px;
            top: 2px;
            transform: rotate(-45deg);
            z-index: 1;
            width: 10px;
            height: 5px;
          }
        }
      }
    }

    &.form-select {
      label {
        flex-flow: column;
        align-items: flex-start;

        .select-wrapper {
          margin-top: 10px;
          &::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            right: 20px;
            top: 7px;
            pointer-events: none;
          }
        }
      }
    }

    .filter {
      margin-top: 10px;
    }

    .select-wrapper {
      width: 100%;
      position: relative;

      select {
        appearance: none;
        width: 100%;
        background: #003366;
        color: #fff;

        font-weight: 500;
        font-size: 16px;
        line-height: 21px;

        border-radius: 5px;
        padding: 5px 20px;

        outline: none;
        border: none;

        &:focus {
          outline: auto;
        }
      }


    }
    .inputs {
      padding: 1rem 0.5rem;
      max-width: 100%;
    }
  }
}
