.tooltip {
    color: var(--logo-dark-blue);
    font-size: var(--font-size-small);
    text-decoration: none;
    width: fit-content;
    // position: relative;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        .tooltip-text {
            opacity: 1;
            z-index: 11;
            top: 80%;
            visibility: visible;
        }
    }

    .tooltip-text {
        opacity: 0;
        visibility: hidden;
        transition: 0.3s all ease-in;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        background-color: white;
        border: 1px solid #CCCCCC;
        border-radius: 5px;
        padding: 1rem;
        overflow: hidden;
        z-index: 0;
    }
}