.ReactModal__Overlay {
  z-index: 999; // Forces modal and overlay above anything else.
  background-color: rgba(0 0 0 / 31%) !important;
}

.ReactModal__Content {
  position: relative;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  // justify-content: space-between;
  margin: auto;

  font-family: Arboria;

  border: 2px solid var(--dark-blue) !important;

  &:has(> div.modal-content.size-mini) {
    max-width: 700px !important;
  }

  &:has(> div.modal-content.size-rb) {
    height: fit-content;
    max-width: 800px !important;
    max-height: 600px !important;
  }

  .modal-header {
    .invision-button, .banner {
      margin: 0;
      margin-top: 1rem;
    }
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      margin: 0.5rem 0 0.5rem;
    }
  }

  .modal-content {
    min-height: 20px;
    flex: 1;

    &:not(.plain) {
      overflow-y: auto;
    }

    .side-by-side {
      // height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      /* Overides for storybook only. */
      margin: 0;
      padding: 0;
      max-width: 100%;

      > section,
      > div,
      > form {
        padding: 0 30px;
        overflow-y: auto;
      }

      > section:nth-child(2),
      > div:nth-child(2),
      > form:nth-child(2) {
        margin: 30px 0 60px;
      }
    }
  }

  .modal-footer {
    margin-top: 8px;

    &.plain {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 10px;

      .modal-buttons {
        padding-right: 20px;
      }
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}
