div.table-wrapper {
  flex-grow: 1;

  .rdg-table {
    height: 100%;

    span.pill {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 8px;
      background-color: #c0c0c0;
      line-height: 0.8rem;
      font-size: 0.8rem;

      &.green {
        background-color: #a1e4cb;
      }

      &.red {
        background-color: #faa9c1;
      }

      &.pill_Gold {
        background-color: #ffd260;
      }

      &.pill_Silver {
        background-color: #c6c6c6;
      }

      &.pill_Bronze {
        background-color: #e7b180;
      }
    }

    div.table-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: inline-block;
      }

      span.table-icon {
        margin: 7px;

        img {
          width: 20px;
          margin-bottom: -3px;
        }
      }
    }
  }
}
