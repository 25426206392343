div.widget-wrapper {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;

  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  div.widget {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    &:nth-last-child(1) {
      margin-right: 0px;
    }

    background-color: #fff;
    border-radius: 8px;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    &.greyed {
      opacity: 0.6;
    }

    h4,
    p {
      margin: 0;
    }

    p {
      margin-top: 5px;
      font-size: 0.8rem;
    }
  }
}
